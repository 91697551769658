
  import { defineComponent, ref, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { detectSubDomain } from "@/core/helpers/helps";
  import { Actions } from "@/store/enums/StoreEnums";
  
  export default defineComponent({
    name: "email-verification",
    components: {
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const isLoading = ref(false);
      const statusEmailVerification = ref(false);
      const errorMessage = ref('');
  
      onMounted(() => {
        const token = router.currentRoute.value.query;
        if (token) {
            sendMailVerification(token);
        } else {
            statusEmailVerification.value = false;
        }
      });

      /**
       * 
       * @param payload object token untuk merequest verifikasi email
       */
      const sendMailVerification = (payload) => {
        isLoading.value = true;
        store
          .dispatch(Actions.POST_EMAIL_VERIFICATION, payload)
          .then((res) => {
            isLoading.value = false;
            statusEmailVerification.value = true;
          })
          .catch(() => {
            statusEmailVerification.value = false;
            errorMessage.value = store.getters.getErrors;
          }).finally(() => {
            isLoading.value = false;
          })
      }
  
      return {
        detectSubDomain,
        isLoading,
        errorMessage,
        statusEmailVerification
      };
    },
  });
  